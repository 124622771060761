<template>
  <th-page-wrapper>
    <!-- Table -->
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      do-route-filters
      show-search-filter
      :show-operations="false"
      no-meta-check
      route-base="/resources/warehouses"
      resource="warehouses"
      headers-filterable
      :resource-query="resourceQuery"
      :headers-default-hide="headersToHide"
      :headers-config="headersConfig"
      :buttons="computedButtons"
      @loading-error="handleLoadingError"
      @headers-config="handleHeadersConfig"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import Decimal from 'decimal.js-light'
import { concatAddressesArray } from './helpers'

export default {
  metaInfo() {
    return {
      title: this.$t('common.resource.warehouse.plural')
    }
  },
  data() {
    return {
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          minWidth: 140,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'custom_id',
          label: this.$t('pages.warehouses.all.headers.id'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'addresses',
          label: this.$t('pages.warehouses.all.headers.address'),
          fallback: '-',
          minWidth: 180,
          truncate: true,
          formatter: (row) => {
            return row.addresses && concatAddressesArray(row.addresses)
          }
        },
        {
          field: 'phonenumbers.line_main',
          label: this.$t('pages.warehouses.all.headers.phonenumbers_main'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => row.phonenumbers && row.phonenumbers.line_main
        },
        {
          field: 'phonenumbers.line_1',
          label: this.$t('pages.warehouses.all.headers.phonenumbers_line_1'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => row.phonenumbers && row.phonenumbers.line_1
        },
        {
          field: 'phonenumbers.line_2',
          label: this.$t('pages.warehouses.all.headers.phonenumbers_line_2'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => row.phonenumbers && row.phonenumbers.line_2
        },
        {
          field: 'location.shelves',
          label: this.$t('pages.warehouses.all.headers.shelves'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'shelves_qty',
          label: this.$t('pages.warehouses.all.headers.shelves_qty'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'warehouse_qty',
          label: this.$t('pages.warehouses.all.headers.warehouse_qty'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'total_assets',
          label: this.$t('pages.warehouses.all.headers.assets'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => {
            if (!row.shelves_qty && !row.warehouse_qty) return null
            const shelvesQty = new Decimal(row.shelves_qty || 0)
            const warehouseQty = new Decimal(row.warehouse_qty || 0)
            return shelvesQty.plus(warehouseQty).toFixed(2)
          }
        },
        {
          field: 'capacity',
          label: this.$t('pages.warehouses.all.headers.capacity'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        }
      ],
      headersToHide: [
        'phonenumbers.main',
        'phonenumbers.mobile',
        'location.shelves',
        'shelves_qty',
        'warehouse_qty',
        'capacity'
      ],
      resourceQuery: {
        query: {
          deleted: false,
          embed: ['shelves', 'warehouse_qty', 'shelves_qty']
        }
      },
      buttons: [
        {
          type: 'create',
          scopes: ['resources:warehouses:create']
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      localConfiguration: 'Config/getLocalConfiguration'
    }),
    headersConfig() {
      return safeGet(
        this.localConfiguration,
        'settings.headerFilters.warehouses',
        {}
      )
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.warehouses.title')
        })
      })
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'settings.headerFilters.warehouses',
        value: config || {}
      })
    }
  }
}
</script>

<style scoped></style>
