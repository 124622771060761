import typeOf from 'just-typeof'
import { formatAddress } from '@/utils/address'

const MAIN_ADDRESS = 'local'
const BILLING_ADDRESS = 'billing'
const DELIVERY_ADDRESS = 'delivery'

const isValidAddress = (address) =>
  address &&
  typeOf(address) === 'object' &&
  (address.street || address.postal_code || address.locality || address.country)

const returnAddressOnValid = (address) =>
  isValidAddress(address) ? address : false

const getAddress = (addresses) =>
  returnAddressOnValid(addresses.find((a) => a.type === MAIN_ADDRESS)) ||
  returnAddressOnValid(addresses.find((a) => a.type === BILLING_ADDRESS)) ||
  returnAddressOnValid(addresses.find((a) => a.type === DELIVERY_ADDRESS)) ||
  null

export const concatAddressesArray = (addresses = []) => {
  if (!Array.isArray(addresses) || !addresses.length) return null
  const address = addresses.length > 1 ? getAddress(addresses) : addresses[0]
  return !isValidAddress(address) ? null : formatAddress(address)
}
